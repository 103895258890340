<template>
  <v-col cols="12">
    <v-card class="d-flex align-center">
      <v-col cols="12">
        <div>
          <div>
            <div style="display: flex; justify-content: space-between; align-items: center">
              <v-card-title> {{ detail.baslik }} </v-card-title>
              <span style="margin-right: 10px; font-weight: bold">{{ detail.create_datetime }}</span>
            </div>

            <v-divider class="black--text" style="color: black; font-weight: bold; border-color: black"></v-divider>
            <v-row>
              <v-col cols="12" md="3"
                ><div class="mx-auto">
                  <v-img :src="'https://onlineapi.anlas.com/' + detail.resim"></v-img></div
              ></v-col>
              <v-col cols="12" md="9">
                <v-card-text class="text--primary text-base">
                  <div style="color: black">
                    {{ detail.content }}
                  </div>
                  <br />
                  <div v-if="detail.files" class="mt-8" style="cursor: pointer; text-decoration: underline">
                    <a
                      class="mr-4 mb-4"
                      v-for="(file, index) in detail.files"
                      :key="index"
                      :href="'https://onlineapi.anlas.com/' + detail.files[index].dosya_adi"
                      target="_blank"
                    >
                      <v-icon>{{ icons.mdiAttachment }}</v-icon>
                      <span class="black--text">{{ detail.files[index].gdosya_adi }}</span>
                      <br />
                    </a>
                  </div> </v-card-text
              ></v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-card>
  </v-col>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import { mdiAttachment } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
export default {
  setup() {
    const { router } = useRouter()
    const detail = ref([])

    onMounted(() => {
      const { id } = router.currentRoute.params

      // const id = 11096
      const method = 'getEventDetail'
      store.dispatch('postMethod', { method, id }).then(response => {
        detail.value = response.detail
        //pageLoading.value = false
      })
    })
    return {
      detail,

      icons: {
        mdiAttachment,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.theme--light.v-sheet .v-card__title {
  color: #ff0000 !important;
  font-weight: bold;
}
</style>
